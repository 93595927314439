<template>
    <div>
        <div v-if="loader == 'LOADING'">
            <div v-for="(item, i) in 4" :key="i">
                <categoriesPlaceholder></categoriesPlaceholder>
            </div>
        </div>
        <div class="grid-view" v-else-if="loader == 'FOUND'">
            <noorPlayGrid
                :contents="screenContent"
                :section="screen"
                :message="''"
                :isScrollable="true"
                :playerInstance="playerInstance"
                :isMetaRedirection = true
                @loadMore="loadMoreContents()"
            ></noorPlayGrid>
        </div>
        <div v-else>
            <p class="not-found-list">{{$t('No Such Contents Found')}}</p>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from "vuex";
    import { store } from "@/store/store";
    export default {
        data () {
            return  {
                loader: 'LOADING',
                screenContent: [],
                screen: {},
                matchCategory: [
                                {'category' : 'hindi-movies', 'objtype':'content'},
                                {'category' : 'hindi-shows', 'objtype':'series'},
                                {'category' : 'content', 'objtype':'content'},
                                {'category' : 'hinidi-video-songs', 'objtype':'content'},
                                {'category' : 'marathi-short-video-bytes', 'objtype':'content'},
                                {'category' : 'marathi-kids', 'objtype':'content'},
                                {'category' : 'marathi-devotional-videos', 'objtype':'content'},
                                ],
                objectType: null,
                currentPage : 1,
                totalcount: null
            }
        },
        props: {
            playerInstance: {
                type: Object,
            }
        },
        methods: {
            loadMoreContents () {
                if (this.totalcount && this.totalcount == this.screenContent.length) {
                    return;
                }
                this.currentPage = this.currentPage + 1;
                this.getPaginationContent();
            },
            getPaginationContent () {
                this.screen = {
                displayType: "PORTRAIT"
                }
      
            console.log("this.$router.currentRoute.params", this.$router.currentRoute.params)
            this.matchCategory.filter((item, i) => {
                if(item.category === this.$router.currentRoute.params.objecttype) {
                    this.objectType = item;
                }
            });
            let customParams = {
                // objecttype: this.objectType ? this.objectType.objtype : this.$router.currentRoute.params.objecttype
            }
            customParams[this.$router.currentRoute.params.type] = this.$router.currentRoute.params.value;

           let  payload =  {
                endpoint: 'subscriber/v1/content',
                params: customParams,
            }
            let localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
            localDisplayLang = localDisplayLang == 'hi' ? 'hin' : localDisplayLang;
            payload.params.displaylanguage = localDisplayLang;
             payload.params.page = this.currentPage;

  
                store
                .dispatch("contentListMetaCall", payload)
                .then((response) => {
                    if (response.data && response.data.errorcode) {
                        this.loader = "NOTFOUND"
                    } else {
                        this.loader = 'FOUND';
                        if (response.data.data.length == 0) return;
                        this.totalcount = response.data.totalcount;
                        this.screenContent = [ ...this.screenContent, ...response.data.data]
                    }
                })
                .catch((err) => console.log(err));
            }
        },
        components: {
            categoriesPlaceholder: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"),
    noorPlayGrid: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/Templates/noorPlayGrid.vue"),
        },
        computed: {
            ...mapGetters(["appConfig"]),
            },
        mounted () {
                this.getPaginationContent()
            }
        }

</script>

<style lang="scss" scoped>
    .grid-view {
        margin: 0 auto;
        width: 100vw;
        height: 100%;
    }
    .not-found-list {
        text-align: center;
        color: #fff;
        padding: 10vh;
    }
</style>
